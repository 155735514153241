html {
  height: 100%;
}

body {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
  > {
    input {
      height: 3.125rem;
      padding: .75rem;
    }
  }
  input {
    &::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::-moz-placeholder, &::placeholder {
      color: transparent;
    }
    &:not(:placeholder-shown) {
      padding-top: 1.25rem;
      padding-bottom: .25rem;
    }
  }
}
